import styles from "./index.module.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

const AttainmentsTable = (props) => {
  const {
    uploadedStatus,
    branch,
    academicYear,
    metricInfo,
    metricsId,
    statusCode,
  } = props;

  const [poDaData, updatePoDaData] = useState([]);
  const [poData, updatePoData] = useState([]);
  const [psoData, updatePsoData] = useState([]);
  const [rowsCount1, setRowsCount1] = useState(1);
  const [rowsCount2, setRowsCount2] = useState(1);
  const [filtDaData, setFiltDaData] = useState({});
  const [filtIdaData, setFiltIdaData] = useState({});
  const [filtOverallData, setFiltOverallData] = useState({});

  const increaseCount = (each) => {
    if (each === 1) {
      setRowsCount1(rowsCount1 + 1);
    }
    if (each === 2) {
      setRowsCount2(rowsCount2 + 1);
    }
  };

  const decreaseCount = (each) => {
    if (each === 1) {
      rowsCount1 > 0 && setRowsCount1(rowsCount1 - 1);
    }

    if (each === 2) {
      rowsCount2 > 0 && setRowsCount2(rowsCount2 - 1);
    }
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    if (!element) {
      console.error("Element not found.");
      return;
    }

    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  const artscrollTbClickedLeft = (each) => {
    const container = document.getElementById(each);
    sideScroll(container, "left", 25, 100, 400);
  };

  const artscrollTbClicked = (each) => {
    const container = document.getElementById(each);
    sideScroll(container, "right", 25, 100, 400);
  };

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);
      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPsoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.MANAGE_CAMPUS_PSO + `?branch=${branch}`,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updatePsoData(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPoDaDetails = async () => {
    try {
      const token = Cookies.get("jwt_token");

      updatePoDaData([]);

      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_DASHBOARD_PO_DA_ATTAINMENT_AY +
          `?batch=${academicYear}&branch=${branch}&academicYear=ALL`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updatePoDaData(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderPosNames = () => {
    try {
      let ans = [];
      for (let each of [...poData, ...psoData]) {
        ans.push(<th key={each["po"]}>{each["po"]}</th>);
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderStructure = (rowsCount, tableName) => {
    let result = [];

    const table = tableName === "da" ? filtDaData : filtIdaData;

    const keys = Object.entries(table);

    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={`${count}-${tableName}-tr`}>
          <td>
            <input
              defaultValue={keys?.[count - 1]?.[0] ?? ""}
              className={styles["label-box"]}
              required
              id={`${tableName}-${count}-courseName`}
            />
          </td>

          {[...poData, ...psoData].map((each) => (
            <td>
              <input
                name={
                  keys?.[count - 1]?.[1]?.[each?.["po"]]?.[0]?.[
                    tableName === "da"
                      ? "po_pso_da_att_id"
                      : "po_pso_ida_att_id"
                  ] ?? ""
                }
                defaultValue={
                  keys?.[count - 1]?.[1]?.[each?.["po"]]?.[0]?.[
                    "po_att_value"
                  ] ?? ""
                }
                className={styles["label-box"]}
                id={`${tableName}-${count}-${each?.["po"]}`}
              />
            </td>
          ))}
        </tr>
      );
    }
    return result;
  };

  const renderOverallTable = (row) => {
    return [...poData, ...psoData].map((each) => (
      <td>
        <input
          defaultValue={
            filtOverallData?.[row]?.[each?.["po"]]?.[0]?.["po_att_value"] ?? ""
          }
          name={
            filtOverallData?.[row]?.[each?.["po"]]?.[0]?.[
              "po_pso_overall_att_id"
            ] ?? ""
          }
          id={`${row}-${each?.["po"]}`}
          className={styles["label-box"]}
        />
      </td>
    ));
  };

  const renderPoDaTable = () => {
    try {
      let ans = [];

      for (let each in poDaData) {
        if (!each.startsWith("PO AT IN % FOR")) {
          let posAns = [];

          for (let item of [...poData, ...psoData]) {
            posAns.push(
              <td className="table-td border border-blue-500 p-2">
                {poDaData?.[each]?.["poPctDetails"]?.[item?.["po"]] ?? ""}
              </td>
            );
          }

          ans.push(
            <tr className="border border-blue-500 text-[#606981] bg-[#FFFFFF] p-0">
              <th className="table-th border border-blue-500 p-2 text-[#040C58]">
                {poDaData[each]["courseShortName"]}
              </th>
              {posAns}
            </tr>
          );
        } else {
          let posAns = [];

          for (let item of [...poData, ...psoData]) {
            posAns.push(
              <th className="table-th border border-blue-500 p-2">
                {poDaData?.[each]?.[item?.["po"]] ?? ""}
              </th>
            );
          }

          ans.push(
            <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
              <th className="table-th border border-blue-500 p-2">
                {each} 4 YEARS
              </th>
              {posAns}
            </tr>
          );
        }
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const daTableSubmit = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count = 1; count <= rowsCount1; count++) {
        const daBody = [...poData, ...psoData].map((each) => ({
          poPsoDaAttId: document.getElementById(`da-${count}-${each?.["po"]}`)
            .name,
          courseName: document.getElementById(`da-${count}-courseName`).value,
          poName: each?.["po"],
          poValue: document.getElementById(`da-${count}-${each?.["po"]}`).value,
        }));

        reqBody = [...reqBody, ...daBody];
      }

      const options = {
        method:
          metricInfo["po-pso-attainment-da-table"].length === 0
            ? "POST"
            : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${"po-pso-attainment-da-table"}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const idaTableSubmit = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count = 1; count <= rowsCount2; count++) {
        const idaBody = [...poData, ...psoData].map((each) => ({
          poPsoIdaAttId: document.getElementById(`ida-${count}-${each?.["po"]}`)
            .name,
          surveyName: document.getElementById(`ida-${count}-courseName`).value,
          poName: each?.["po"],
          poValue: document.getElementById(`ida-${count}-${each?.["po"]}`)
            .value,
        }));

        reqBody = [...reqBody, ...idaBody];
      }

      const options = {
        method:
          metricInfo["po-pso-attainment-ida-table"].length === 0
            ? "POST"
            : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${"po-pso-attainment-ida-table"}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const overAllTableSubmit = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count of ["DA", "IDA", "OVERALL"]) {
        const overAllBody = [...poData, ...psoData].map((each) => ({
          poPsoOverallAttId: document.getElementById(`${count}-${each?.["po"]}`)
            .name,
          assessmentName: count,
          poName: each?.["po"],
          poValue: document.getElementById(`${count}-${each?.["po"]}`).value,
        }));

        reqBody = [...reqBody, ...overAllBody];
      }

      const options = {
        method:
          metricInfo["po-pso-attainment-overall-table"].length === 0
            ? "POST"
            : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${"po-pso-attainment-overall-table"}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const formatData = () => {
    try {
      const groupByCourse = Object.groupBy(
        metricInfo?.["po-pso-attainment-da-table"],
        ({ course_name }) => course_name
      );

      const resultCourse = Object.fromEntries(
        Object.entries(groupByCourse).map(([course_name, group]) => {
          const groupedByPo = Object.groupBy(group, ({ po_name }) => po_name);
          return [course_name, groupedByPo];
        })
      );

      const groupBySurvey = Object.groupBy(
        metricInfo?.["po-pso-attainment-ida-table"],
        ({ survey_name }) => survey_name
      );

      const resultSurvey = Object.fromEntries(
        Object.entries(groupBySurvey).map(([survey_name, group]) => {
          const groupedByPo = Object.groupBy(group, ({ po_name }) => po_name);
          return [survey_name, groupedByPo];
        })
      );

      const groupByAssessment = Object.groupBy(
        metricInfo?.["po-pso-attainment-overall-table"],
        ({ assessment_name }) => assessment_name
      );

      const resultAssessment = Object.fromEntries(
        Object.entries(groupByAssessment).map(([assessment_name, group]) => {
          const groupedByPo = Object.groupBy(group, ({ po_name }) => po_name);
          return [assessment_name, groupedByPo];
        })
      );

      setFiltDaData(resultCourse);

      const daCount =
        Object.keys(resultCourse).length > 0
          ? Object.keys(resultCourse).length
          : 1;

      setRowsCount1(daCount);

      setFiltIdaData(resultSurvey);

      const idaCount =
        Object.keys(resultSurvey).length > 0
          ? Object.keys(resultSurvey).length
          : 1;

      setRowsCount2(idaCount);

      setFiltOverallData(resultAssessment);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPoData();
    formatData();
  }, []);

  useEffect(() => {
    branch !== undefined && getPsoData();
  }, [branch]);

  useEffect(() => {
    statusCode !== undefined && statusCode === "303" && getPsoData();
  }, [statusCode]);

  useEffect(() => {
    academicYear !== undefined &&
      branch !== undefined &&
      statusCode === "303" &&
      getPoDaDetails();
  }, [academicYear, branch, statusCode]);

  return (
    <div className="pl-2">
      <form onSubmit={daTableSubmit} className="flex flex-col ">
        <div className="w-[355px]">
          <div className="flex flex-col justify-start flex-wrap mt-2 w-full">
            <div className={styles.table}>
              <div
                id="daTable"
                className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
              >
                <table className={styles["attainments-table"]}>
                  <thead>
                    <tr>
                      <th className={styles["course-info"]}>Course</th>

                      {renderPosNames()}
                    </tr>
                  </thead>
                  <tbody>
                    {statusCode !== "303"
                      ? renderStructure(rowsCount1, "da")
                      : renderPoDaTable()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <span className="flex justify-end self-end mb-5 mt-3">
          <span
            onClick={() => artscrollTbClickedLeft("daTable")}
            className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
          <span
            onClick={() => artscrollTbClicked("daTable")}
            className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
        </span>

        {statusCode !== "303" && (
          <>
            <span className="flex justify-end self-end mb-5">
              <button
                onClick={() => decreaseCount(1)}
                type="button"
                className={styles["plus-btn"]}
              >
                -
              </button>

              <button
                onClick={() => increaseCount(1)}
                type="button"
                className={styles["plus-btn"]}
              >
                +
              </button>
            </span>
            {rowsCount1 > 0 && (
              <button
                className={`${styles["submit-btn"]} mt-2 mb-5`}
                type="submit"
              >
                Submit
              </button>
            )}
          </>
        )}
      </form>

      <form onSubmit={idaTableSubmit} className="flex flex-col">
        <div className="w-[355px]">
          <div className="flex flex-col justify-start flex-wrap mt-2 w-full">
            <div className={styles.table}>
              <div
                id="idaTable"
                className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
              >
                <table className={styles["attainments-table"]}>
                  <thead>
                    <tr>
                      <th className={styles["course-info"]}>
                        Name of the Survey
                      </th>

                      {renderPosNames()}
                    </tr>
                  </thead>
                  <tbody>{renderStructure(rowsCount2, "ida")}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <span className="flex justify-end self-end mt-3 mb-5">
          <span
            onClick={() => artscrollTbClickedLeft("idaTable")}
            className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
          <span
            onClick={() => artscrollTbClicked("idaTable")}
            className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
        </span>

        <span className="flex justify-end self-end mb-5">
          <button
            onClick={() => decreaseCount(2)}
            type="button"
            className={styles["plus-btn"]}
          >
            -
          </button>

          <button
            onClick={() => increaseCount(2)}
            type="button"
            className={styles["plus-btn"]}
          >
            +
          </button>
        </span>
        {rowsCount2 > 0 && (
          <button className={`${styles["submit-btn"]} mt-2 mb-5`} type="submit">
            Submit
          </button>
        )}
      </form>

      <form onSubmit={overAllTableSubmit} className="flex flex-col">
        <div className="w-[355px]">
          <div className="flex flex-col justify-start flex-wrap mt-2 w-full">
            <div className={styles.table}>
              <div
                id="overAllTable"
                className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
              >
                <table className={styles["attainments-table"]}>
                  <thead>
                    <tr>
                      <th className={styles["course-info"]}>Assessment</th>

                      {renderPosNames()}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Direct Attainment</td>

                      {renderOverallTable("DA")}
                    </tr>
                    <tr>
                      <td>Indirect Attainment</td>
                      {renderOverallTable("IDA")}
                    </tr>
                    <tr>
                      <td>Overall Attainment</td>
                      {renderOverallTable("OVERALL")}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <span className="flex justify-end self-end mt-3 mb-5">
          <span
            onClick={() => artscrollTbClickedLeft("overAllTable")}
            className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
          <span
            onClick={() => artscrollTbClicked("overAllTable")}
            className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
        </span>

        <button className={`${styles["submit-btn"]} mt-2 mb-5`} type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AttainmentsTable;
