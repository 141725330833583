import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const semValues = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
};

const PublicationsJournalsNewsletters = (props) => {
  const [rowsCount1, setRowsCount1] = useState(1);
  const [rowsCount2, setRowsCount2] = useState(1);
  const [rowsCount3, setRowsCount3] = useState(1);
  const [filtData, setFiltData] = useState({});

  const { template, metricInfo, metricsId, uploadedStatus } = props;

  const increaseCount = (each) => {
    if (each === 1) {
      setRowsCount1(rowsCount1 + 1);
    }
    if (each === 2) {
      setRowsCount2(rowsCount2 + 1);
    }
    if (each === 3) {
      setRowsCount3(rowsCount3 + 1);
    }
  };

  const decreaseCount = (each) => {
    if (each === 1) {
      rowsCount1 > 0 && setRowsCount1(rowsCount1 - 1);
    }

    if (each === 2) {
      rowsCount2 > 0 && setRowsCount2(rowsCount2 - 1);
    }

    if (each === 3) {
      rowsCount3 > 0 && setRowsCount3(rowsCount3 - 1);
    }
  };

  const submitData = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count = 1; count <= rowsCount1; count++) {
        reqBody.push({
          publicationJournalsNewslettersId: document.getElementById(
            `CAYm1-${count}-publication-name`
          ).name,
          publicationName: document.getElementById(
            `CAYm1-${count}-publication-name`
          ).value,
          editorName: document.getElementById(`CAYm1-${count}-editor-name`)
            .value,
          studentName: document.getElementById(`CAYm1-${count}-student-name`)
            .value,
          semester: document.getElementById(`CAYm1-${count}-semester`).value,
          issuesCount: document.getElementById(`CAYm1-${count}-issues-count`)
            .value,
          copyType: document.getElementById(`CAYm1-${count}-copy-type`).value,
          year: "CAYm1",
        });
      }

      for (let count = 1; count <= rowsCount2; count++) {
        reqBody.push({
          publicationJournalsNewslettersId: document.getElementById(
            `CAYm2-${count}-publication-name`
          ).name,
          publicationName: document.getElementById(
            `CAYm2-${count}-publication-name`
          ).value,
          editorName: document.getElementById(`CAYm2-${count}-editor-name`)
            .value,
          studentName: document.getElementById(`CAYm2-${count}-student-name`)
            .value,
          semester: document.getElementById(`CAYm2-${count}-semester`).value,
          issuesCount: document.getElementById(`CAYm2-${count}-issues-count`)
            .value,
          copyType: document.getElementById(`CAYm2-${count}-copy-type`).value,
          year: "CAYm2",
        });
      }

      for (let count = 1; count <= rowsCount3; count++) {
        reqBody.push({
          publicationJournalsNewslettersId: document.getElementById(
            `CAYm3-${count}-publication-name`
          ).name,
          publicationName: document.getElementById(
            `CAYm3-${count}-publication-name`
          ).value,
          editorName: document.getElementById(`CAYm3-${count}-editor-name`)
            .value,
          studentName: document.getElementById(`CAYm3-${count}-student-name`)
            .value,
          semester: document.getElementById(`CAYm3-${count}-semester`).value,
          issuesCount: document.getElementById(`CAYm3-${count}-issues-count`)
            .value,
          copyType: document.getElementById(`CAYm3-${count}-copy-type`).value,
          year: "CAYm3",
        });
      }

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderStructure = (rowsCount, year) => {
    let result = [];
    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={`${count}-tr`}>
          <td>{count}</td>
          <td className={styles["input-col"]}>
            <input
              name={
                filtData?.[year]?.[count - 1]?.[
                  "publication_journals_newsletters_id"
                ] ?? ""
              }
              defaultValue={
                filtData?.[year]?.[count - 1]?.["publication_name"] ?? ""
              }
              id={`${year}-${count}-publication-name`}
              required
            />
          </td>
          <td className={styles["input-col"]}>
            <input
              id={`${year}-${count}-editor-name`}
              defaultValue={
                filtData?.[year]?.[count - 1]?.["editor_name"] ?? ""
              }
              required
            />
          </td>

          <td className={styles["input-col"]}>
            <input
              id={`${year}-${count}-student-name`}
              defaultValue={
                filtData?.[year]?.[count - 1]?.["student_name"] ?? ""
              }
              required
            />
          </td>
          <td>
            <select
              id={`${year}-${count}-semester`}
              required
              className={`${styles["filter-select"]} focus:ring-0 text-blue-600`}
            >
              <option
                selected={
                  (filtData?.[year]?.[count - 1]?.["semester"] ?? "") === ""
                }
                disabled
                hidden
                value=""
              >
                SELECT
              </option>
              {Object.entries(semValues).map(([key, val]) => (
                <option
                  selected={
                    `${filtData?.[year]?.[count - 1]?.["semester"]}` === key
                  }
                  value={key}
                >
                  {val}
                </option>
              ))}
            </select>
          </td>
          <td>
            <input
              id={`${year}-${count}-issues-count`}
              defaultValue={
                filtData?.[year]?.[count - 1]?.["issues_count"] ?? ""
              }
              type="number"
              required
              min={0}
            />
          </td>
          <td>
            <select
              id={`${year}-${count}-copy-type`}
              required
              className={`${styles["filter-select"]} focus:ring-0 text-blue-600`}
            >
              <option
                selected={
                  (filtData?.[year]?.[count - 1]?.["copy_type"] ?? "") === ""
                }
                disabled
                hidden
                value=""
              >
                SELECT
              </option>
              <option
                selected={
                  filtData?.[year]?.[count - 1]?.["copy_type"] === "Hard"
                }
                value="Hard"
              >
                Hard
              </option>

              <option
                selected={
                  filtData?.[year]?.[count - 1]?.["copy_type"] === "Soft"
                }
                value="Soft"
              >
                Soft
              </option>
            </select>
          </td>
        </tr>
      );
    }
    return result;
  };

  const formatData = () => {
    try {
      const updatedData = Object.groupBy(
        metricInfo?.[template],
        ({ year }) => year
      );

      const m1Count = updatedData?.["CAYm1"]?.length ?? 1;
      const m2Count = updatedData?.["CAYm2"]?.length ?? 1;
      const m3Count = updatedData?.["CAYm3"]?.length ?? 1;

      setFiltData(updatedData);
      setRowsCount1(m1Count);
      setRowsCount2(m2Count);
      setRowsCount3(m3Count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    formatData();
  }, []);

  return (
    <form onSubmit={submitData} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["public-journal-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>Name of the Journal, Magazine, Newsletter</th>
            <th>Name of the Editor</th>
            <th>Name of the Student</th>
            <th>Semester</th>
            <th>No. of Issues</th>
            <th>Hard copy / Soft copy</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={7}>CAYM1</td>
          </tr>
          {renderStructure(rowsCount1, "CAYm1")}
        </tbody>
      </table>

      <span className="flex justify-end self-end pb-6">
        <button
          onClick={() => decreaseCount(1)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(1)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      <table className={styles["public-journal-table"]}>
        <thead>
          <tr>
            <th className={styles["head-center"]}>S.N</th>
            <th>Name of the Journal, Magazine, Newsletter</th>
            <th>Name of the Editor</th>
            <th>Name of the Student</th>
            <th>Semester</th>
            <th>No. of Issues</th>
            <th>Hard copy / Soft copy</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={7}>CAYM2</td>
          </tr>
          {renderStructure(rowsCount2, "CAYm2")}
        </tbody>
      </table>

      <span className="flex justify-end self-end pb-6">
        <button
          onClick={() => decreaseCount(2)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(2)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      <table className={styles["public-journal-table"]}>
        <thead>
          <tr>
            <th className={styles["head-center"]}>S.N</th>
            <th>Name of the Journal, Magazine, Newsletter</th>
            <th>Name of the Editor</th>
            <th>Name of the Student</th>
            <th>Semester</th>
            <th>No. of Issues</th>
            <th>Hard copy / Soft copy</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={7}>CAYM3</td>
          </tr>
          {renderStructure(rowsCount3, "CAYm3")}
        </tbody>
      </table>

      <span className="flex justify-end self-end ">
        <button
          onClick={() => decreaseCount(3)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(3)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      {rowsCount1 + rowsCount2 + rowsCount3 > 0 && (
        <button className={styles["submit-btn"]} type="submit">
          Submit
        </button>
      )}
    </form>
  );
};

export default PublicationsJournalsNewsletters;
