import { useEffect, useState } from "react";
import styles from "./index.module.css";
import Info from "./Info";
import { Link, withRouter } from "react-router-dom";
import CoAttainmentTable from "./CoAttainmentTable";
import PoAttainmentTable from "./PoAttainmentTable";
import Observations from "./Observations";
import Cookies from "js-cookie";
import * as jose from "jose";
import apiClient from "../../../utils/apiUrls/apiClient";
import roles from "../../roles";
import Analysis from "./Analysis";
import WeightsAndTargets from "./WeightsAndTargets";
import ExamMarks from "./ExamMarks";
import Students from "./Students";
import MappedQuestionPaper from "../QuestionPaperMapping/QuestionPaperScreen/MappedQuestionPaper";
import QuestionPaperAnalysis from "../QuestionPaperMapping/QuestionPaperScreen/QuestionPaperAnalysis";
import { Oval } from "react-loader-spinner";

const preDefinedTabs = [
  "INFO",
  "WEIGHTAGES & TARGETS",
  "CO AT",
  "PO AT",
  "OBSERVATIONS",
  "ANALYSIS",
  "STUDENTS",
  "QUESTION PAPER MAPPING",
  "QUESTION PAPER ANALYSIS",
];

const romanNum = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
};

const Attainments = (props) => {
  const [loader, updateLoader] = useState(true);
  const [tabs, updateTabs] = useState(preDefinedTabs);
  const [activeTab, updateTab] = useState(preDefinedTabs[0]);
  const [courses, updateCourses] = useState([]);
  const [activeCourse, updateActiveCourse] = useState(null);

  const [batch, updateBatch] = useState("");
  const [branch, updateBranch] = useState("");
  const [sem, updateSem] = useState("");
  const [sec, updateSec] = useState("");
  const [coList, updateCoList] = useState([]);

  const [courseCode, updateCourseCode] = useState(null);
  const [batchOptions, updateBatchOptions] = useState([]);
  const [branchOptions, updateBranchOptions] = useState([]);
  const [semOptions, updateSemOptions] = useState([]);
  const [courseCodesOptions, updateCourseCodesOptions] = useState([]);
  const [secOptions, updateSecOptions] = useState([]);
  const [structure, updateStructure] = useState({});
  const [internalTypes, updateInternalTypes] = useState([]);
  const [externalTypes, updateExternalTypes] = useState([]);

  const { match } = props;
  const { path, params } = match;

  const token = Cookies.get("jwt_token");
  const claim = jose.decodeJwt(token);
  const { dept_name, user_type } = claim;

  const renderLoader = () => (
    <div className="relative flex justify-center mr-32 mt-24 mb-16">
      <Oval
        height={60}
        width={60}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  const renderCourseInfo = () => (
    <div className="flex items-center p-2">
      <div className={`${styles["course-info"]} mr-10`}>
        <span className="bg-[#F5FAFE] rounded-xl p-3 py-5 mr-3">
          {activeCourse?.courseCode}
        </span>
        <span className="flx flex-col">
          <h1>
            {activeCourse?.courseShortName} ({activeCourse?.courseName})
          </h1>
          <p className="text-sm text-[#8F949F] mt-2">
            {activeCourse?.branch} - {romanNum?.[activeCourse?.semester]}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({activeCourse?.batch})
          </p>
        </span>
      </div>

      <div className="mr-20">
        <p className={styles["structure-info-heading"]}>Course Type:</p>
        <p className={styles["structure-info"]}>{activeCourse?.courseType}</p>
      </div>

      <div className="mr-20">
        <p className={styles["structure-info-heading"]}>Lecturer:</p>
        <p className={styles["structure-info"]}>{activeCourse?.facultyName}</p>
      </div>

      <div className="flex flex-col justify-end">
        <p className={`${styles["structure-info-heading"]}`}>Section:</p>
        <select
          onChange={filtersChanged}
          id="sec"
          value={sec}
          className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
        >
          <option value="">All</option>
          {renderSecOptions()}
        </select>
      </div>

      <Link
        className={styles["report-btn"]}
        to={`/obe/courses/managecourses/pdf/${courseCode}/${batch}/${branch}/${sem}`}
        target="_blank"
      >
        <button type="button">Generate Report</button>
      </Link>
    </div>
  );

  const tabChanged = (event) => {
    updateTab(event.target.id);
  };

  const renderTabView = () => {
    const templateList = [...internalTypes, ...externalTypes];

    switch (activeTab) {
      case "INFO":
        return (
          <Info
            courseCode={courseCode}
            activeCourse={activeCourse}
            branch={branch}
            sec={sec}
            secOptions={secOptions}
            internalTypes={internalTypes}
            externalTypes={externalTypes}
          />
        );

      case "CO AT":
        return (
          <CoAttainmentTable
            courseCode={courseCode}
            batch={batch}
            branch={branch}
            sem={sem}
            sec={sec}
            secOptions={secOptions}
          />
        );

      case "PO AT":
        return (
          <PoAttainmentTable
            courseCode={courseCode}
            batch={batch}
            branch={branch}
            sem={sem}
            activeCourse={activeCourse}
            secOptions={secOptions}
          />
        );

      case "OBSERVATIONS":
        return <Observations courseCode={courseCode} />;

      case "ANALYSIS":
        return <Analysis data={activeCourse} courseCode={courseCode} />;

      case "WEIGHTAGES & TARGETS":
        return (
          <WeightsAndTargets
            courseCode={courseCode}
            sec={sec}
            secOptions={secOptions}
            activeCourse={activeCourse}
          />
        );

      case "STUDENTS":
        return (
          <Students courseCode={courseCode} branch={branch} batch={batch} />
        );

      case "QUESTION PAPER MAPPING":
        return (
          <MappedQuestionPaper
            data={activeCourse}
            courseCode={courseCode}
            templateList={templateList}
            isAttainment
          />
        );

      case "QUESTION PAPER ANALYSIS":
        return (
          <QuestionPaperAnalysis
            data={activeCourse}
            courseCode={courseCode}
            templateList={templateList}
            isAttainment
          />
        );

      default:
        return (
          <ExamMarks
            courseCode={courseCode}
            sec={sec}
            secOptions={secOptions}
            activeTab={activeTab}
          />
        );
    }
  };

  const getCourses = async () => {
    const token = Cookies.get("jwt_token");

    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_MANAGE_COURSES_LIST +
          `?batch=&branch=&regulation=&semester=&section=`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateCourses(response["data"]["result"]["courseDetailsResponses"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCourseCodes = async () => {
    try {
      const token = Cookies.get("jwt_token");

      if (batch !== "" && branch !== "" && sem !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.COURSE_ANALYSIS_CODE_OPTIONS +
            `?batch=${batch}&branch=${branch}&semester=${sem}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          updateCourseCodesOptions(response["data"]["result"]);
          const { courseId = null } = params;

          if (courseId === null) {
            updateCourseCode(response["data"]["result"][0]["courseId"]);
            const { history } = props;
            history.replace(
              `/faculty/dashboard/attainments/${response["data"]["result"][0]["courseId"]}`
            );
          }
        } else {
          updateCourseCode(null);
          updateCourseCodesOptions([]);
        }
        updateLoader(false);
      }
    } catch (err) {
      console.log(err);
      updateCourseCode(null);
      updateCourseCodesOptions([]);
    }
  };

  const getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length !== 0) {
          const { courseId = null } = params;

          courseId === null &&
            updateBatch(response["data"]["result"][0]["optionValue"]);
          updateBatchOptions(response["data"]["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length !== 0) {
          const { courseId = null } = params;

          courseId === null &&
            (user_type === roles.principal || user_type === roles.faculty) &&
            updateBranch(response["data"]["result"][0]["optionValue"]);

          user_type === roles.hod && updateBranch(dept_name);

          updateBranchOptions(response["data"]["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length !== 0) {
          const { courseId = null } = params;

          courseId === null &&
            updateSem(response["data"]["result"][0]["optionValue"]);

          updateSemOptions(response["data"]["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      let batchVal = batchOptions.filter(
        (each) => each.optionValue === `${batch}`
      );
      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${branch}&batch=${batchVal[0]["optionDesc"]}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length > 0) {
          updateSecOptions(response["data"]["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of batchOptions) {
        ans.push(
          <li
            className={
              `${batch}` === `${item["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item["optionValue"]}
            id="batch"
            value={item["optionValue"]}
            onClick={filtersChanged}
          >
            {item["optionDesc"]}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSemOptions = () => {
    const ans = [];
    try {
      for (const item of semOptions) {
        ans.push(
          <li
            className={
              `${sem}` === `${item["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item["optionValue"]}
            id="sem"
            value={item["optionValue"]}
            onClick={filtersChanged}
          >
            {item["optionDesc"]}
          </li>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSecOptions = () => {
    const ans = [];
    try {
      for (const item of secOptions) {
        ans.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const filtersChanged = (event) => {
    const { history } = props;

    switch (event.target.id) {
      case "batch":
        if (batch === event.target.value) break;

        updateBatch(event.target.value);
        history.replace(`/faculty/dashboard/attainments`);

        break;
      case "branch":
        if (sem === event.target.value) break;

        updateBranch(event.target.value);
        history.replace(`/faculty/dashboard/attainments`);

        break;
      case "sem":
        if (sem === event.target.value) break;
        updateSem(event.target.value);
        history.replace(`/faculty/dashboard/attainments`);

        break;
      case "courseCode":
        updateCourseCode(event.target.value);

        history.replace(`/faculty/dashboard/attainments/${event.target.value}`);

        break;
      case "sec":
        updateSec(event.target.value);

        break;
      default:
        break;
    }
  };

  const getStructures = async () => {
    updateStructure({});
    updateInternalTypes([]);
    updateExternalTypes([]);

    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.COURSE_STRUCTURES +
          `?courseStructId=${activeCourse?.courseStructId}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateStructure(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const tableStruct = () => {
    try {
      const item = structure;

      const intTheory =
        item.intTheoryShortList === null
          ? ""
          : item.intTheoryShortList.split(",");

      const intPract =
        item.intPractShortList === null
          ? ""
          : item.intPractShortList.split(",");

      const intProj =
        item.intProjShortList === null ? "" : item.intProjShortList.split(",");

      const internal = intTheory.concat(intPract).concat(intProj);

      const internalValues = [];

      for (const each of internal) {
        if (each !== "") {
          internalValues.push(each);
        }
      }

      const extTheory =
        item.extTheoryShortList === null
          ? ""
          : item.extTheoryShortList.split(",");

      const extPract =
        item.extPractShortList === null
          ? ""
          : item.extPractShortList.split(",");
      const extProj =
        item.extProjShortList === null ? "" : item.extProjShortList.split(",");

      const external = extTheory.concat(extPract).concat(extProj);

      const externalValues = [];

      for (const each of external) {
        if (each !== "") {
          externalValues.push(each);
        }
      }

      updateInternalTypes(internalValues);
      updateExternalTypes(externalValues);
    } catch (err) {
      console.log(err);
    }
  };

  const getDescCo = async (id) => {
    updateTabs(preDefinedTabs);
    updateCoList([]);

    const token = Cookies.get("jwt_token");
    try {
      const responseDesc = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_FEEDBACK_CO_DESCRIPTION +
          `?courseId=${id}`,
        { Authorization: token }
      );

      if (responseDesc["data"]["statusCode"] === "OK") {
        updateCoList(responseDesc["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCourses();
    getBatchOptions();
    getBranchOptions();
    getSemOptions();
    const { courseId = null } = params;

    courseId !== null && updateCourseCode(courseId);
  }, []);

  useEffect(() => {
    Object.keys(structure).length > 0 && tableStruct();
  }, [structure]);

  useEffect(() => {
    const coNames = coList.map((each) => each?.["coName"]);

    updateTabs([preDefinedTabs[0], ...coNames, ...preDefinedTabs.slice(1)]);
  }, [coList]);

  useEffect(() => {
    const { courseId = null } = params;

    if (activeCourse !== null && courseId !== null) {
      getStructures();
      getDescCo(activeCourse?.courseId);
      updateBatch(activeCourse?.batchId);
      updateBranch(activeCourse?.branch);
      updateSem(activeCourse?.semester);
    }
  }, [activeCourse]);

  useEffect(() => {
    getCourseCodes();
    getSectionOptions();
  }, [batch, branch, sem]);

  useEffect(() => {
    if (courseCode !== null) {
      const allCourses = [...courses];

      const filteredCourse = allCourses.filter(
        (each) => each.courseId === parseInt(courseCode)
      );

      filteredCourse.length > 0
        ? updateActiveCourse(filteredCourse[0])
        : updateActiveCourse(null);
    } else {
      updateActiveCourse(null);
    }
  }, [courseCode, courses]);

  const toggleButtons = (name) => {
    const el = document.getElementById(name);
    if (el !== null) {
      if (el.scrollWidth > el.clientWidth) {
        return true;
      }
      return false;
    }
  };

  const analysisTabLeft = () => {
    const container = document.getElementById("analysis-tabs");
    sideScroll(container, "left", 25, 100, 400);
  };

  const analysisTab = () => {
    const container = document.getElementById("analysis-tabs");
    sideScroll(container, "right", 25, 100, 400);
  };

  const batchTabLeft = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "left", 25, 250, 20);
  };

  const batchTab = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "right", 25, 250, 20);
  };

  const semTabLeft = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "left", 25, 150, 20);
  };

  const semTab = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "right", 25, 150, 20);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <div className="flex flex-col overflow-y-auto overflow-x-hidden">
      <div className="ml-7">
        <div className="flex flex-row mt-24">
          <Link to="/faculty/dashboard/managecourses">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/faculty/dashboard/managecourses"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-4 mb-1 text-[#040C58]  underline-offset-8 decoration-2 cursor-pointer`}
            >
              MANAGE COURSES
            </h1>
          </Link>

          <Link to="/faculty/dashboard/attainments">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path.startsWith("/faculty/dashboard/attainments")
                  ? "font-bold underline"
                  : "font-medium"
              } ml-6 mb-1 text-[#040C58]  underline-offset-8 decoration-2 cursor-pointer`}
            >
              ATTAINMENTS
            </h1>
          </Link>
        </div>
      </div>

      <div className={`${styles["students-bg"]} mb-2 pl-3`}>
        <div className={`${styles["students-filters"]} w-32 mr-3 ml-2`}>
          <select
            value={branch}
            id="branch"
            className={`${styles["filters"]} focus:ring-0 text-blue-600 border-none mt-7 mb-1.5 pr-5`}
            onChange={filtersChanged}
          >
            {renderBranchOptions()}
          </select>
        </div>

        <div className={`${styles["students-filters"]} ml-1 w-6/12`}>
          <h1 className={`${styles["filters-title"]}`}>Choose Batch</h1>
          <div className="flex relative">
            <button onClick={batchTabLeft} className={styles["arrow-buttons"]}>
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
            <ul id="batch-tabs" className={styles["batches-ul"]}>
              {renderBatchOptions()}
            </ul>

            <button
              onClick={batchTab}
              className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
            >
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
          </div>
        </div>

        <div className={`${styles["students-filters"]} ml-4 w-3/12`}>
          <h1 className={`${styles["filters-title"]}`}>Semester</h1>

          <div className="flex relative">
            <button onClick={semTabLeft} className={styles["arrow-buttons"]}>
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
            <ul
              id="sem-tabs"
              className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
            >
              {renderSemOptions()}
            </ul>

            <button
              onClick={semTab}
              className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
            >
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
          </div>
        </div>

        {courseCodesOptions.length !== 0 && (
          <div className={`${styles["students-filters"]} w-32 ml-4`}>
            <select
              onChange={filtersChanged}
              id="courseCode"
              value={courseCode}
              className={`${styles["filters"]} focus:ring-0 text-blue-600 border-none mt-7 mb-1.5 pr-5`}
            >
              {courseCodesOptions.map((each) => (
                <option key={each["courseId"]} value={each["courseId"]}>
                  {each["courseShortName"]}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <span
        className={`${styles["bg-container"]} pb-8 ml-4 border border-t-0 rounded-l-xl rounded-tr-xl`}
      >
        <div className={styles["analysis-container"]}>
          {loader ? (
            renderLoader()
          ) : courseCode === null || (activeCourse === null && !loader) ? (
            <div className="flex justify-center items-center p-10 mt-5 mb-5">
              <p className={styles["empty-view"]}>
                {activeCourse === null
                  ? "You don't have access to view this course attainments"
                  : "Please Select Course To View Attainments & Analysis"}
              </p>
            </div>
          ) : (
            <>
              {renderCourseInfo()}

              <span className="bg-[#fff] p-2 pt-1 pb-0.5 flex items-center">
                {toggleButtons("analysis-tabs") && (
                  <span
                    onClick={analysisTabLeft}
                    className={`${styles["floatarr"]} self-end relative right-1 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
                  >
                    <img
                      src="/assets/floatarrow.svg"
                      alt="arrow"
                      width={8}
                      height={8}
                    />
                  </span>
                )}
                <ul id="analysis-tabs" className={styles["analysis-tabs-ul"]}>
                  {tabs.map((each) => (
                    <li
                      key={each}
                      id={each}
                      onClick={tabChanged}
                      className={each === activeTab && styles["tab-active"]}
                    >
                      {each}
                    </li>
                  ))}
                </ul>

                {toggleButtons("analysis-tabs") && (
                  <span
                    onClick={analysisTab}
                    className="relative right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
                  >
                    <img
                      src="/assets/floatarrow.svg"
                      alt="arrow"
                      width={8}
                      height={8}
                    />
                  </span>
                )}
              </span>
            </>
          )}
        </div>

        {loader
          ? renderLoader()
          : courseCode !== null && (
              <div
                className={preDefinedTabs.includes(activeTab) && "pl-6 pr-6"}
              >
                {activeCourse !== null && renderTabView()}
              </div>
            )}
      </span>
    </div>
  );
};

export default withRouter(Attainments);
