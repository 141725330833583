import styles from "./index.module.css";
import { Oval } from "react-loader-spinner";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const ExamMarks = (props) => {
  const [loader, updateLoader] = useState(false);
  const [marks, updateMarks] = useState({});
  const [rollNums, updateRollNums] = useState([]);
  const [questions, updateQuestions] = useState({});

  const { courseCode, sec, secOptions, activeTab } = props;

  const renderEmptyView = (text) => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>{text}</p>
    </div>
  );

  const renderLoader = () => (
    <div className="relative flex justify-center mr-32 mt-24 mb-16">
      <Oval
        height={60}
        width={60}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  const getMarks = async () => {
    updateLoader(true);
    updateMarks({});
    updateRollNums([]);
    updateQuestions({});

    const joinedSec = secOptions.join();

    const sectionsVal = sec === "" ? joinedSec : sec;

    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_ATTAINMENTS_CO_TEMPLATE_MARKS +
          `?courseId=${courseCode}&sectionList=${sectionsVal}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateMarks(response?.["data"]?.["result"] ?? {});
      }
    } catch (err) {
      console.log(err);
    }

    updateLoader(false);
  };

  const renderAssessTypeHeader = () => {
    const assessTypes = Object.keys(
      marks?.["questionHeaderDetails"]?.["Internal"]?.[activeTab] ?? {}
    ).map((each) => {
      const colCount = Object.keys(
        marks?.["questionHeaderDetails"]?.["Internal"]?.[activeTab]?.[each] ??
          {}
      ).length;

      return (
        <>
          <th colSpan={colCount === 0 ? 0 : colCount - 1}>{each}</th>
          {colCount > 0 && (
            <th className={styles["marks-table-total"]}>Total</th>
          )}
        </>
      );
    });

    assessTypes.length > 0 &&
      assessTypes.push(
        <>
          <th rowSpan={3} className={styles["marks-table-total"]}>
            Internal Obtained Marks
          </th>
          <th rowSpan={3} className={styles["marks-table-total"]}>
            Internal Attained
          </th>
        </>
      );

    const assessTypesExt = Object.keys(
      marks?.["questionHeaderDetails"]?.["External"]?.[activeTab] ?? {}
    ).map((each) => {
      const colCount = Object.keys(
        marks?.["questionHeaderDetails"]?.["External"]?.[activeTab]?.[each] ??
          {}
      ).length;

      return (
        <>
          <th colSpan={colCount === 0 ? 0 : colCount - 1}>{each}</th>
          {colCount > 0 && (
            <th className={styles["marks-table-total"]}>Total</th>
          )}
        </>
      );
    });

    assessTypesExt.length > 0 &&
      assessTypesExt.push(
        <>
          <th rowSpan={3} className={styles["marks-table-total"]}>
            External Obtained Marks
          </th>
          <th rowSpan={3} className={styles["marks-table-total"]}>
            External Attained
          </th>
        </>
      );

    return [...assessTypes, ...assessTypesExt];
  };

  const renderMarksHeader = () => {
    const assessTypes = Object.keys(
      marks?.["questionHeaderDetails"]?.["Internal"]?.[activeTab] ?? {}
    );

    const assessTypesExt = Object.keys(
      marks?.["questionHeaderDetails"]?.["External"]?.[activeTab] ?? {}
    );

    const marksHeaderData = [];

    assessTypes.forEach((each) => {
      let qstnMarks = [];
      let totalMaxMarks = [];

      const qstns = Object.entries(
        marks?.["questionHeaderDetails"]?.["Internal"]?.[activeTab]?.[each]
      );

      qstns.forEach((item) => {
        if (item[0] !== "questionMaxMarks") {
          qstnMarks.push(<td>{item[1]}</td>);
        } else {
          totalMaxMarks.push(
            <td className={styles["marks-table-total-value"]}>{item[1]}</td>
          );
        }
      });

      marksHeaderData.push([...qstnMarks, ...totalMaxMarks]);
    });

    assessTypesExt.forEach((each) => {
      let qstnMarks = [];
      let totalMaxMarks = [];

      const qstns = Object.entries(
        marks?.["questionHeaderDetails"]?.["External"]?.[activeTab]?.[each]
      );

      qstns.forEach((item) => {
        if (item[0] !== "questionMaxMarks") {
          qstnMarks.push(<td>{item[1]}</td>);
        } else {
          totalMaxMarks.push(
            <td className={styles["marks-table-total-value"]}>{item[1]}</td>
          );
        }
      });

      marksHeaderData.push([...qstnMarks, ...totalMaxMarks]);
    });

    return marksHeaderData;
  };

  const renderQuestionsHeader = () => {
    const assessTypes = Object.keys(
      marks?.["questionHeaderDetails"]?.["Internal"]?.[activeTab] ?? {}
    );

    const assessTypesExt = Object.keys(
      marks?.["questionHeaderDetails"]?.["External"]?.[activeTab] ?? {}
    );

    const qstnsHeaderData = [];

    assessTypes.forEach((each) => {
      let qstnCount = 0;

      const qstns = Object.entries(
        marks?.["questionHeaderDetails"]?.["Internal"]?.[activeTab]?.[each]
      );

      qstns.forEach((item) => {
        if (item[0] !== "questionMaxMarks") {
          qstnCount += 1;
          qstnsHeaderData.push(<td>{item[0]}</td>);
        }
      });

      qstnsHeaderData.push(
        <td className={styles["marks-table-total-value"]}>{qstnCount}</td>
      );
    });

    assessTypesExt.forEach((each) => {
      let qstnCount = 0;

      const qstns = Object.entries(
        marks?.["questionHeaderDetails"]?.["External"]?.[activeTab]?.[each]
      );

      qstns.forEach((item) => {
        if (item[0] !== "questionMaxMarks") {
          qstnCount += 1;
          qstnsHeaderData.push(<td>{item[0]}</td>);
        }
      });

      qstnsHeaderData.push(
        <td className={styles["marks-table-total-value"]}>{qstnCount}</td>
      );
    });

    return qstnsHeaderData;
  };

  const renderStdMarks = (roll) => {
    const stdMarks = [];
    stdMarks.push(<th>{roll}</th>);

    const mainTypes = Object.keys(questions);

    mainTypes.forEach((main) => {
      const assessTypes = Object.keys(questions?.[main]);

      assessTypes.forEach((type) => {
        let total = 0;

        questions?.[main]?.[type]?.forEach((item) => {
          total =
            total +
            parseFloat(marks?.[main]?.[activeTab]?.[roll]?.[type]?.[item] ?? 0);

          stdMarks.push(
            <td>{marks?.[main]?.[activeTab]?.[roll]?.[type]?.[item] ?? ""}</td>
          );
        });

        stdMarks.push(
          <td className={styles["marks-table-total-value"]}>{total}</td>
        );
      });
      if (marks?.[main]) {
        const att =
          marks?.[main]?.[activeTab]?.[roll]?.["stAttDetails"]?.["stAtt"] ?? "";

        stdMarks.push(
          <>
            <td className={styles["marks-table-total-value"]}>
              {marks?.[main]?.[activeTab]?.[roll]?.["stAttDetails"]?.[
                "stCoObtMarks"
              ] ?? ""}
            </td>
            <td className={styles["marks-table-total-value"]}>
              <span className={att === "Y" ? "text-green-500" : "text-red-500"}>
                {att}
              </span>
            </td>
          </>
        );
      }
    });

    return stdMarks;
  };

  const filterMarks = () => {
    try {
      const questionNos = {
        Internal: {},
        External: {},
      };

      const assessTypes = Object.keys(
        marks?.["questionHeaderDetails"]?.["Internal"]?.[activeTab] ?? {}
      );

      const assessTypesExt = Object.keys(
        marks?.["questionHeaderDetails"]?.["External"]?.[activeTab] ?? {}
      );

      assessTypes.forEach((type) => {
        if (!questionNos["Internal"][type]) {
          questionNos["Internal"][type] = [];
        }

        const qstns = Object.entries(
          marks?.["questionHeaderDetails"]?.["Internal"]?.[activeTab]?.[type]
        );

        qstns.forEach((item) => {
          if (item[0] !== "questionMaxMarks") {
            questionNos["Internal"][type] = [
              ...questionNos["Internal"][type],
              item[0],
            ];
          }
        });
      });

      assessTypesExt.forEach((type) => {
        if (!questionNos["External"][type]) {
          questionNos["External"][type] = [];
        }

        const qstns = Object.entries(
          marks?.["questionHeaderDetails"]?.["External"]?.[activeTab]?.[type]
        );

        qstns.forEach((item) => {
          if (item[0] !== "questionMaxMarks") {
            questionNos["External"][type] = [
              ...questionNos["External"][type],
              item[0],
            ];
          }
        });
      });

      const roll = Object.keys(marks?.["Internal"]?.[activeTab] ?? {});

      const rollExt = Object.keys(marks?.["External"]?.[activeTab] ?? {});

      const uniqueRolls = [...new Set([...roll, ...rollExt])].filter(
        (each) => each !== "coCalcDetails"
      );

      updateRollNums(uniqueRolls);
      updateQuestions(questionNos);
    } catch (err) {
      console.log(err);
    }
  };

  const renderThresholdTable = () => {
    try {
      const isInternal = marks?.["Internal"] ? true : false;
      const isExternal = marks?.["External"] ? true : false;

      let colCount = 0;

      if (isInternal) colCount += 1;
      if (isExternal) colCount += 1;

      const type = isInternal ? "Internal" : "External";

      return (
        <tbody>
          <tr>
            <th>Attempts (A)</th>

            {isInternal && (
              <td>
                {marks?.["Internal"]?.[activeTab]?.["coCalcDetails"]?.[
                  "totalAttempts"
                ] ?? ""}
              </td>
            )}

            {isExternal && (
              <td>
                {marks?.["External"]?.[activeTab]?.["coCalcDetails"]?.[
                  "totalAttempts"
                ] ?? ""}
              </td>
            )}
          </tr>

          <tr>
            <th>Threshold</th>

            <td colSpan={colCount}>
              <input
                id={`${activeTab}-threshold-value`}
                defaultValue={
                  marks?.[type]?.[activeTab]?.["coCalcDetails"]?.[
                    "threshold"
                  ] ?? ""
                }
              />
              %
            </td>
          </tr>

          <tr>
            <th>Score Above Threshold (T)</th>

            {isInternal && (
              <td>
                {marks?.["Internal"]?.[activeTab]?.["coCalcDetails"]?.[
                  "scoresAboveTh"
                ] ?? ""}
              </td>
            )}

            {isExternal && (
              <td>
                {marks?.["External"]?.[activeTab]?.["coCalcDetails"]?.[
                  "scoresAboveTh"
                ] ?? ""}
              </td>
            )}
          </tr>

          <tr className="bg-[#EFF3FF]">
            <th>Attainment - (T/A)</th>

            {isInternal && (
              <td>
                {marks?.["Internal"]?.[activeTab]?.["coCalcDetails"]?.[
                  "coAtt"
                ] ?? ""}
              </td>
            )}

            {isExternal && (
              <td>
                {marks?.["External"]?.[activeTab]?.["coCalcDetails"]?.[
                  "coAtt"
                ] ?? ""}
              </td>
            )}
          </tr>
        </tbody>
      );
    } catch (err) {
      console.log(err);
    }
  };

  // const updateCoWt = async () => {
  //   updateLoader(true);
  //   const token = Cookies.get("jwt_token");
  //   try {
  //     const reqBody = Object.keys(marks?.coFooterSection).map((each) => {
  //       const val = document.getElementById(each).value;

  //       return {
  //         co: each,
  //         courseId: courseCode,
  //         templateName: activeTab,
  //         thValue: val,
  //       };
  //     });

  //     const options = {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(reqBody),
  //     };

  //     const response = await fetch(
  //       apiClient.urls.fatcat
  //         .OBE_ATTAINMENTS_CO_SPECIFIC_TEMPLATE_WEIGHTAGE_UPDATE,
  //       options
  //     );
  //     const res = await response.json();

  //     if (res["result"] === "OK") {
  //       getMarks();
  //     } else {
  //       alert("Weightages are not updated");
  //       updateLoader(false);
  //     }
  //   } catch (err) {
  //     updateLoader(false);
  //     alert("Weightages are not updated");
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    getMarks();
  }, [courseCode, activeTab, sec]);

  useEffect(() => {
    filterMarks();
  }, [marks]);

  return loader ? (
    renderLoader()
  ) : !marks?.["questionHeaderDetails"]?.["Internal"]?.[activeTab] &&
    !marks?.["questionHeaderDetails"]?.["External"]?.[activeTab] ? (
    renderEmptyView("No data found")
  ) : (
    <span className="bg-[#fff]">
      <div className={styles["marks-table-container"]}>
        <table className={`${styles["marks-table"]} mt-8`}>
          <tr>
            <td></td>
            {renderAssessTypeHeader()}
          </tr>
          <tr>
            <td>Max Marks</td>
            {renderMarksHeader()}
          </tr>

          <tr>
            <td>Question</td>
            {renderQuestionsHeader()}
          </tr>

          <tbody>
            {rollNums.map((each) => (
              <tr>{renderStdMarks(each)}</tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-row mt-10 bg-[#fff] pl-6 pr-6">
        <table className={styles["threshold-table"]}>
          <tr className="bg-[#EFF3FF]">
            <td></td>
            {marks?.["Internal"] && <th>Internal</th>}
            {marks?.["External"] && <th>External</th>}
          </tr>
          {renderThresholdTable()}
        </table>

        {/* <div className={styles["std-atd-info"]}>
          <button
            type="button"
            onClick={updateCoWt}
            className={styles["threshold-btn"]}
          >
            Update
          </button>
        </div> */}
      </div>
    </span>
  );
};

export default ExamMarks;
